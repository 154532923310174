<template>
  <div class="wrapper">
    <Crumb :title.sync="title" :menu.sync="menu" />
    <Banner :img.sync="img" />
    <div class="container">
      <div class="content">
        <ul class="links-list clearfix">
          <li v-for="item in linksList" :key="item._id">
            <a :href="item.brief" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Crumb from "../components/Crumb.vue";
import Banner from "../components/Banner.vue";
export default {
  components: { Crumb, Banner },
  data() {
    return {
      title: "",
      menu: [],
      img: require("../assets/images/banner1.jpg"),
      linksList: [],
    };
  },
  created() {
    this.getLinksList();
  },
  methods: {
    getLinksList() {
      let type = this.$route.query.type;
      this.axios
        .get("https://s.icloudportal.com/api/siteReleasedPosts", {
          params: {
            site: "m3FntHT5iWaBQqcGW",
            type: type,
          },
        })
        .then((response) => {
          this.linksList = response.data.data.list;
          this.linksList.reverse();
          if (type == "hKk9rEqQFtNBxaZG8") {
            document.title = "政府网站 - 长春凯达";
          } else if (type == "rcwkwWN3q9zcEbTHk") {
            document.title = "党建人社 - 长春凯达";
          } else if (type == "Xzbq5SeJrnyd9zDhq") {
            document.title = "平台企业 - 长春凯达";
          } else if (type == "bhmaeNxJkccYvPLAs") {
            document.title = "建工企业 - 长春凯达";
          } else if (type == "h5KWSqALLfJcj6Shu") {
            document.title = "财经金融 - 长春凯达";
          } else if (type == "PQM7LsbN8yuaD5PAn") {
            document.title = "咨询机构 - 长春凯达";
          } else if (type == "wJcqjd54mDnBGRK5g") {
            document.title = "合作单位 - 长春凯达";
          } else if (type == "K5KA3fFujFGMiF8ds") {
            document.title = "合作高校 - 长春凯达";
          }
          // console.log("res:", response.data);
        })
        .catch((error) => {
          console.log("err:", error);
        });
    },
  },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .content {
      .links-list {
        li {
          float: left;
          width: 380px;
          text-align: center;
          margin: 9px 9px 9px 9px;
          padding: 10px 0;
          border: 1px solid #ccc;
          cursor: pointer;
        }
        li:hover {
          background: #cee6fe;
        }
      }
    }
  }
}
</style>
